.table-wrapper{
    overflow: auto;
    margin-bottom: 1rem;
}
.card-header.border-top-none{
    @include border-top-radius(0)
}
.myDropzone{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: $gray-200;
    text-align: center;
    &:hover{
        background-color: $gray-400;
    }
}
.upload-dropzone{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table__hidden-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.table__hidden-button{
    padding: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:last-child{
        margin-right: 0;
    }
    .form-check-label{
        cursor: pointer;
        padding: .5rem .7rem;
    }
}
.page-loading{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-select__input{
    opacity: 1 !important;
    &:disabled{
        color: hsl(0, 0%, 60%) !important;
    }
}
.react-select__input-container{
    visibility: visible !important;
}
.react-select__multi-value--is-disabled{
    .react-select__multi-value__label{
        color: hsl(0, 0%, 60%);
    }
    .react-select__multi-value__remove > svg{
        fill: hsl(0, 0%, 60%);
    }
}

.home{
    min-height: 100vh;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.card-btn__title{
    margin-left: .5rem;
}
.back-button{
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
}
.preview-wrapper{
    height: 100vh;
    position: sticky;
    top: 0;
    padding: 25px 20px;
    overflow-y: auto;
}