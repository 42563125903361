// Modals
// *******************************************************************************

// Modal Shadow
.modal-content {
  box-shadow: $modal-content-box-shadow-xs;
}

// Modal Header close btn style
.modal {
  .btn-close {
    background-color: $white;
    border-radius: $border-radius-lg;
    opacity: 1;
    padding: 0.635rem;
    box-shadow: $box-shadow-sm;
    transition: all 0.23s ease 0.1s;

    @include ltr-style {
      transform: translate(23px, -25px);
    }

    // For hover effect of close btn
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      outline: none;

      @include ltr-style {
        transform: translate(20px, -20px);
      }
    }
  }
  .modal-header {
    .btn-close {
      margin-top: -1.25rem;
    }
  }
}

// modal footer
.modal-footer {
  padding: $modal-footer-padding;
}

// ! remove close button animation & shadow for .modal-dialog-scrollable, .modal-fullscreen, .modal-top modal
.modal-dialog-scrollable,
.modal-fullscreen,
.modal-top {
  .btn-close {
    box-shadow: none;
    @include ltr-style {
      transform: translate(0, 0) !important;
    }

    &:hover {
      @include ltr-style {
        transform: translate(0, 0) !important;
      }
    }
  }
}

// Top modals
// *******************************************************************************

.modal-top {
  .modal-dialog {
    margin-top: 0;
  }

  .modal-content {
    @include border-top-radius(0);
  }
}

// Responsive
// *******************************************************************************

@include media-breakpoint-down(lg) {
  .modal-onboarding .onboarding-horizontal {
    flex-direction: column;
  }
}
@include media-breakpoint-down(md) {
  .modal {
    .modal-dialog:not(.modal-fullscreen) {
      padding: 0 0.75rem;
      padding-left: 0.75rem !important;
    }
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }
}
@include media-breakpoint-up(sm) {
  .modal-content {
    box-shadow: $modal-content-box-shadow-sm-up;
  }
  .modal-sm .modal-dialog {
    max-width: $modal-sm;
  }
}
@include media-breakpoint-up(xl) {
  .modal-xl .modal-dialog {
    max-width: $modal-xl;
  }
}
