// Labels
// *******************************************************************************

.form-label,
.col-form-label {
  font-size: $form-label-font-size;
  text-transform: $form-label-text-transform;
  letter-spacing: $form-label-letter-spacing;
}
// Default (vertical ) form label size
.form-label-lg {
  @include font-size($input-font-size-lg);
}

.form-label-sm {
  @include font-size($input-font-size-sm);
}
