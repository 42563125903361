// Menu
// *******************************************************************************

.menu {
  display: flex;

  .app-brand {
    width: 100%;
  }

  //PS Scrollbar
  .ps__thumb-y,
  .ps__rail-y {
    width: 0.125rem !important;
  }

  .ps__rail-y {
    right: 0.25rem !important;
    left: auto !important;
    background: none !important;
  }

  .ps__rail-y:hover,
  .ps__rail-y:focus,
  .ps__rail-y.ps--clicking,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking > .ps__thumb-y {
    width: 0.375rem !important;
  }
}

.menu-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  height: 100%;
}
.menu-inner-shadow {
  display: none;
  position: absolute;
  top: $navbar-height + 0.35rem;
  height: 3rem;
  width: 100%;
  pointer-events: none;
  z-index: 2;
  // Hide menu inner shadow in static layout
  html:not(.layout-menu-fixed) & {
    display: none !important;
  }
}

// Menu item

.menu-item {
  align-items: flex-start;
  justify-content: flex-start;

  &.menu-item-animating {
    transition: height $menu-animation-duration ease-in-out;
  }
}

.menu-item,
.menu-header,
.menu-divider,
.menu-block {
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-header {
  opacity: 1;
  transition: opacity $menu-animation-duration ease-in-out;
}

// Menu Icon
.menu-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: $menu-icon-expanded-spacer;
  font-size: $menu-icon-expanded-font-size;
  .menu:not(.menu-no-animation) & {
    transition: margin-right $menu-animation-duration ease;
  }
}

// Menu link
.menu-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin: 0;

  .menu-item.disabled & {
    cursor: not-allowed !important;
  }
  // link hover animation
  .menu:not(.menu-no-animation) & {
    transition-duration: $menu-animation-duration;
    transition-property: color, background-color;
  }

  > :not(.menu-icon) {
    flex: 0 1 auto;
    opacity: 1;
    .menu:not(.menu-no-animation) & {
      transition: opacity $menu-animation-duration ease-in-out;
    }
  }
}

// Sub menu
.menu-sub {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;

  .menu:not(.menu-no-animation) & {
    transition: background-color $menu-animation-duration;
  }

  .menu-item.open > & {
    display: flex;
  }
}

// Menu toggle open/close arrow
.menu-toggle::after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: $caret-width;
  height: $caret-width;
  border: 1px solid;
  border-bottom: 0;
  border-left: 0;
  transform: translateY(-50%) rotate(45deg);

  .menu-item.open:not(.menu-item-closing) > & {
    transform: translateY(-50%) rotate(135deg);
  }

  .menu:not(.menu-no-animation) & {
    transition-duration: $menu-animation-duration;
    transition-property: -webkit-transform, transform;
  }
}

// Menu divider
.menu-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid;
}

// Vertical Menu
// *******************************************************************************

.menu-vertical {
  // overflow: hidden;
  flex-direction: column;

  // menu expand collapse animation
  &:not(.menu-no-animation) {
    transition: width $menu-animation-duration;
  }

  &,
  .menu-block,
  .menu-inner > .menu-item,
  .menu-inner > .menu-header {
    width: $menu-width;
  }

  .menu-inner {
    flex-direction: column;
    flex: 1 1 auto;

    > .menu-item {
      margin: $menu-item-spacer 0;
      // Sneat menu-link spacing
      .menu-link {
        margin: $menu-vertical-link-margin-y $menu-vertical-link-margin-x;
      }
    }
  }

  .menu-item .menu-link,
  .menu-block {
    padding: $menu-vertical-link-padding-y $menu-vertical-link-padding-x;
  }
  // Sneat menu-header spacing
  .menu-header {
    margin: $menu-vertical-header-margin-y 0 $menu-vertical-header-margin-y * 0.5 0;
    padding: $menu-vertical-link-padding-y $menu-vertical-link-padding-x * 2 $menu-vertical-link-padding-y
      $menu-vertical-link-padding-x * 2;
  }
  .menu-item .menu-link {
    font-size: $menu-font-size;
  }

  .menu-item.active:not(.open) > {
    .menu-link {
      font-weight: $font-weight-semibold;
    }
  }
  .menu-item .menu-toggle {
    padding-right: calc(#{$menu-vertical-link-padding-x} + #{$caret-width * 3});

    &::after {
      right: $menu-vertical-link-padding-x;
    }
  }

  .menu-divider {
    margin-top: $menu-vertical-link-padding-y;
    margin-bottom: $menu-vertical-link-padding-y;
    padding: 0;
  }

  .menu-sub {
    padding-top: $menu-vertical-menu-link-padding-y * 0.5;
    padding-bottom: $menu-vertical-menu-link-padding-y * 0.5;

    .menu-link {
      padding-top: $menu-vertical-menu-link-padding-y;
      padding-bottom: $menu-vertical-menu-link-padding-y;
    }
  }

  .menu-icon {
    width: $menu-icon-expanded-width;
  }

  .menu-sub .menu-icon {
    margin-right: 0;

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  .menu-horizontal-wrapper {
    flex: none;
  }

  // Levels
  //

  $menu-first-level-spacer: $menu-vertical-link-padding-x + $menu-icon-expanded-width + $menu-icon-expanded-spacer;

  .menu-sub .menu-link {
    padding-left: $menu-first-level-spacer;
  }
  // Menu levels loop for padding left/right
  @for $i from 2 through $menu-max-levels {
    $selector: '';

    @for $l from 1 through $i {
      $selector: '#{$selector} .menu-sub';
    }

    #{$selector} .menu-link {
      padding-left: $menu-first-level-spacer + ($menu-vertical-menu-level-spacer * ($i - 1));
    }
  }
}

// Vertical Menu Collapsed
// *******************************************************************************
// ! Updated menu collapsed styles for sneat in this mixin
@mixin layout-menu-collapsed() {
  width: $menu-collapsed-width;

  .menu-inner > .menu-item {
    width: $menu-collapsed-width;
  }
  .menu-inner > .menu-item > .menu-link {
    padding-left: $menu-vertical-link-margin-x;
  }

  .menu-inner > .menu-header,
  .menu-block {
    position: relative;
    margin-left: $menu-vertical-header-margin-y;
    padding-right: ($menu-vertical-link-padding-x * 2) - $menu-icon-expanded-spacer;
    padding-left: $menu-icon-expanded-spacer;
    width: $menu-width;
    text-indent: -9999px;
    text-overflow: ellipsis;
    white-space: nowrap;
    .menu-header-text {
      overflow: hidden;
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: ($menu-collapsed-width * 0.5) - $menu-vertical-header-margin-y - 0.5;
      display: block;
      width: 1rem;
      text-align: center;
      top: 1.1875rem;
    }
  }
  // Custom for sneat only
  .menu-block {
    &::before {
      bottom: 0.75rem;
    }
  }
  .menu-inner > .menu-item div:not(.menu-block) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
  }
  .menu-inner > .menu-item > .menu-sub,
  .menu-inner > .menu-item.open > .menu-sub {
    display: none;
  }
  .menu-inner > .menu-item > .menu-toggle::after {
    display: none;
  }

  .menu-inner > .menu-item > .menu-link .menu-icon {
    margin-left: -($menu-vertical-link-margin-x * 2);
    width: $menu-collapsed-width;
    text-align: center;
    margin-right: 0;
  }
}

// Only for menu example
.menu-collapsed:not(:hover) {
  @include layout-menu-collapsed();
}
