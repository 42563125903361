// Tooltips
// *******************************************************************************

// Open modal tooltip z-index
.modal-open .tooltip {
  z-index: $zindex-modal + 2;
}

.tooltip-inner {
  box-shadow: $tooltip-box-shadow;
}
