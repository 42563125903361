// Offcanvas
// *******************************************************************************

.offcanvas-header {
  padding-bottom: $offcanvas-padding-y * 0.5;
}

.offcanvas-body {
  padding-top: $offcanvas-padding-y * 0.5;
}
