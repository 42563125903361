// Validation states
// *******************************************************************************

@each $state, $data in $form-validation-states {
  @include template-form-validation-state($state, $data...);
}

// Currently supported for formvalidation and jq-validation
form {
  .error:not(li):not(input) {
    color: $form-feedback-invalid-color;
    font-size: 85%;
    margin-top: 0.25rem;
  }

  .invalid,
  .is-invalid .invalid:before,
  .is-invalid::before {
    border-color: $form-feedback-invalid-color !important;
  }

  .form-label {
    &.invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 2px rgba($form-feedback-invalid-color, 0.4) !important;
    }
  }

  select {
    &.invalid {
      & ~ .select2 {
        .select2-selection {
          border-color: $form-feedback-invalid-color;
        }
      }
    }

    // FormValidation

    //Select2
    &.is-invalid {
      & ~ .select2 {
        .select2-selection {
          border-color: $form-feedback-invalid-color !important;
        }
      }
    }
    // Bootstrap select
    &.selectpicker {
      &.is-invalid {
        ~ .btn {
          border-color: 1px solid $form-feedback-invalid-color;
          border-color: $form-feedback-invalid-color;
        }
      }
    }
  }
}
