// Form control
// *******************************************************************************

.form-control {
  &::placeholder {
    transition: $input-placeholder-transition;
  }
  &:focus::placeholder {
    transform: $input-placeholder-transform;
    transition: $input-placeholder-transition;
  }
}
