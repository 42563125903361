// Common
// *******************************************************************************

$ui-star-size: 1.1em !default;
$ui-stars-spacer: -0.1em !default;
$ui-star-filled-color: $yellow !default;

// Navbar (custom navbar)
// *******************************************************************************
$navbar-height: 3.875rem !default;
$navbar-suggestion-width: 96% !default;
$navbar-suggestion-height: 28rem !default;
$navbar-suggestion-border-radius: 0.5rem !default;
$navbar-dropdown-width: 22rem !default;
$navbar-dropdown-content-height: 30rem !default;
$navbar-notifications-dropdown-item-padding: 1rem !default;

// Menu
// *******************************************************************************

$menu-width: 16.25rem !default;
$menu-collapsed-width: 5.25rem !default;

$menu-font-size: $font-size-base !default;

$menu-item-spacer: 0.0625rem !default;

$menu-vertical-link-margin-y: 0rem !default;
$menu-vertical-link-margin-x: 1rem !default;

$menu-vertical-header-margin-y: 1rem !default;
$menu-vertical-header-margin-x: 0 !default;

$menu-vertical-link-padding-y: 0.625rem !default;
$menu-vertical-link-padding-x: 1rem !default;

$menu-vertical-menu-link-padding-y: 0.625rem !default;
$menu-vertical-menu-level-spacer: 0.65rem !default;

$menu-horizontal-item-spacer: 0.325rem !default;
$menu-horizontal-link-padding-y: 0.565rem !default;
$menu-horizontal-link-padding-x: 1rem !default;
$menu-horizontal-menu-link-padding-y: 0.625rem !default;
$menu-horizontal-menu-level-spacer: 2.35rem !default;
$menu-horizontal-menu-box-shadow: $dropdown-box-shadow !default;

$menu-sub-width: 14.5rem !default;
$menu-control-width: 2.25rem !default;
$menu-control-arrow-size: 0.5rem !default;

$menu-icon-expanded-width: 1.5rem !default;
$menu-icon-expanded-font-size: 1.25rem !default;
$menu-icon-expanded-spacer: 0.5rem !default;

$menu-animation-duration: 0.3s !default;

$menu-max-levels: 5 !default;

$menu-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$menu-dark-menu-bg: rgba(0, 0, 0, 0.06) !default;
$menu-light-border-color: rgba(0, 0, 0, 0.06) !default;
$menu-light-menu-bg: rgba(0, 0, 0, 0.05) !default;

// Avatars
// *******************************************************************************

$avatar-size-xl: 4.5rem !default;
$avatar-size-lg: 4rem !default;
$avatar-size-md: 3rem !default;
$avatar-size: 2.375rem !default; // Default
$avatar-size-sm: 2rem !default;
$avatar-size-xs: 1.625rem !default;

$avatar-initial-xl: 1.875rem !default;
$avatar-initial-lg: 1.5rem !default;
$avatar-initial-md: 1.125rem !default;
$avatar-initial-sm: 0.75rem !default;
$avatar-initial-xs: 0.625rem !default;

$avatar-group-border: $white !default;

// Text Divider
// *******************************************************************************
$divider-color: $gray-200 !default;

$divider-margin-y: 1rem !default;
$divider-margin-x: 0 !default;

$divider-text-padding-y: 0rem !default;
$divider-text-padding-x: 1rem !default;

$divider-font-size: 0.8rem !default;
$divider-icon-size: 1rem !default;
