// List groups
// *******************************************************************************

// List Group Mixin
@each $color, $value in $theme-colors {
  @if $color != primary and $color != light {
    @include template-list-group-item-variant('.list-group-item-#{$color}', $value);
  }
}

.list-group {
  // Timeline CSS
  &.list-group-timeline {
    position: relative;
    &:before {
      background-color: $border-color;
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0.2rem;
    }
    .list-group-item {
      border: none;
      padding-left: 1.25rem;
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 7px;
        height: 7px;
        left: 0;
        top: 50%;
        margin-top: -3.5px;
        border-radius: 100%;
      }
    }
  }

  .list-group-item.active {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      color: $list-group-active-color;
    }
  }
}
