// Spinners
//

//Large size
.spinner-border-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
  border-width: $spinner-border-width-lg;
}

.spinner-grow-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
  border-width: $spinner-border-width-lg;
}

@include keyframes('spinner-border-rtl') {
  to {
    transform: rotate(-360deg);
  }
}
