// * Toast
// *******************************************************************************

@mixin template-toast-variant($parent, $background, $color: null) {
  $label-color: if($color, $color, $background);
  $color: if($color, $color, color-contrast($background));

  #{$parent} {
    &.toast,
    &.bs-toast {
      color: $white;
      background-color: rgba($background, $toast-bg-factor) !important;
      box-shadow: 0 0.25rem 1rem rgba($background, 0.4);

      .toast-header {
        color: $white;
        .btn-close {
          background-color: $background !important;
          background-image: str-replace(
            str-replace(str-replace($btn-close-bg, '#{$btn-close-color}', $white), '#', '%23'),
            "fill-opacity='0.5'",
            "fill-opacity='1'"
          );
          box-shadow: 0 0.1875rem 0.375rem 0 rgba($background, 0.4) !important;
        }
      }
    }
  }
}

@mixin template-toast-theme($parent, $background, $color: null) {
  @include template-toast-variant($parent, $background, $color);
}
